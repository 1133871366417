import React from "react";
import { connect } from "react-redux";
import { Box, Button, Grid, Typography } from "@mui/material";
import { loginWithGoogle } from "../models/Users";
import { getMessage } from "../helpers/Messages";
import Loading from "../components/Loading";

const LoginForm = ({ dispatch, message }) => {
	const [loading, setLoading] = React.useState(false);

	const handleGoogleSignIn = () => {
		setLoading(true);
		loginWithGoogle(dispatch).then(() => {
			setLoading(false);
		});
	};

	return (
		<Box>
			<Grid
				pt={5}
				container={true}
				flexDirection={["column", "column", "column", "column", "row"]}
			>
				{message && (
					<Grid item xs={12}>
						<Box color="red.main" fontSize="12px" fontWeight="bold">
							{message}
						</Box>
					</Grid>
				)}
				<Grid
					item
					xs={12}
					sx={{ display: "flex", justifyContent: "center" }}
					mb={3}
				>
					{!loading ? (
						<Button onClick={handleGoogleSignIn} variant="primary">
							Login with Google
						</Button>
					) : (
						<Box>
							<Loading />
						</Box>
					)}
				</Grid>
				<Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
					<Typography variant="small">{getMessage("loginDefault")}</Typography>
				</Grid>
			</Grid>
		</Box>
	);
};

function mapStateToProps(state) {
	return {
		message: state.User.message,
	};
}

export default connect(mapStateToProps)(LoginForm);
